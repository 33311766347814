import { BarChart } from "@mantine/charts"

import { Filters } from "@costory/types/filters"

import { ChartTooltip } from "@costory/front/components/charts/ChartTooltip"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useExplorerQuery } from "@costory/front/queries/explore"
import { getCharteSerieColor } from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
  drillDownInto?: (value: string) => void
}

export const StackedBarChart = ({
  filters,
  height,
  withLegend = true,
  drillDownInto,
}: Props) => {
  const explorerQuery = useExplorerQuery(filters)
  return (
    <QueryWrapper query={explorerQuery}>
      {({ data }) => (
        <BarChart
          h={height ?? "100%"}
          pr="30px"
          data={data.stackedBarChartData.arr}
          dataKey="agg_date"
          type="stacked"
          withLegend={withLegend}
          xAxisProps={{
            angle: 30,
            textAnchor: "start",
          }}
          tooltipProps={{
            content: ({ label, payload }) => (
              <ChartTooltip label={label} payload={payload} />
            ),
          }}
          valueFormatter={(value) => formatNumber(value)}
          series={data.stackedBarChartData.items.map((item, index) => ({
            name: item.name,
            color: getCharteSerieColor(index),
          }))}
          barProps={{
            // copy paste of Tremor approach to know which bar was clicked...
            onClick: (data) =>
              drillDownInto && drillDownInto(data.tooltipPayload[0].dataKey),
          }}
        />
      )}
    </QueryWrapper>
  )
}
