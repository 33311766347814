import { Filters } from "@costory/types/filters"

import { TableChart } from "@costory/front/components/charts/TableExplorer"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useWaterfallQuery } from "@costory/front/queries/explore"
import { getBestDrillDown } from "@costory/front/utils/columns"

type Props = {
  filters: Filters
  drillDownInto: (groupBy: string, value: string, newGroupBy: string) => void
}

export const ExplorerTable = ({ filters, drillDownInto }: Props) => {
  const waterfallQuery = useWaterfallQuery(filters)

  return (
    <QueryWrapper query={waterfallQuery}>
      {({ data }) => (
        <TableChart
          data={data.dataGroupedByValue}
          drillDownInto={(value) =>
            drillDownInto(
              filters.groupBy,
              value,
              getBestDrillDown(filters.groupBy),
            )
          }
        />
      )}
    </QueryWrapper>
  )
}
