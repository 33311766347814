import "@mantine/core/styles.css"
import "@mantine/dates/styles.css"
import "@mantine/notifications/styles.css"
import "@mantine/charts/styles.css"
import "mantine-react-table/styles.css"

import { ClerkProvider } from "@clerk/clerk-react"
import { useMantineColorScheme } from "@mantine/core"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { RouterProvider } from "react-router-dom"

import { CLERK_THEMES } from "@costory/front/assets/theme/clerk"
import { queryClient } from "@costory/front/lib/queryClient"
import { router } from "@costory/front/pages/router"

import "./reset.css"

const CLERK_PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Clerk Publishable Key")
}

function App() {
  const { colorScheme } = useMantineColorScheme()
  return (
    <ClerkProvider
      appearance={CLERK_THEMES[colorScheme === "dark" ? "dark" : "light"]}
      publishableKey={CLERK_PUBLISHABLE_KEY}
      afterSignOutUrl="/"
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ClerkProvider>
  )
}

export default App
