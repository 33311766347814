import { Table } from "@mantine/core"

import { VirtualDimensionsRequests } from "@costory/types/endpoints/virtualDimensions"

import { formatNumber } from "@costory/front/utils/format"

type Props = {
  openModalSplitCost?: () => void
  costRule: number | undefined
  allocation: VirtualDimensionsRequests.EditVirtualDimension["rules"][0]["allocation"]
}
export default function SplitCostResult({
  openModalSplitCost,
  allocation,
  costRule,
}: Props) {
  if (!(allocation.allocationType === "splitCost")) {
    return <></>
  }
  if (allocation.reAllocationParams.type == "custom") {
    const partitions = allocation.reAllocationParams.partitions
    const totalAllocated = partitions.reduce(
      (previous, current) => previous + current.weight,
      0,
    )
    const partitionsWithUnallocated = [
      ...allocation.reAllocationParams.partitions,
      ...(100 - totalAllocated > 0
        ? [{ label: "unAllocated", weight: 100 - totalAllocated }]
        : []),
    ]
    const rows = partitionsWithUnallocated.map((element) => (
      <Table.Tr key={element.label}>
        <Table.Td>{element.label}</Table.Td>
        <Table.Td>{element.weight}</Table.Td>
        <Table.Td>
          {costRule
            ? formatNumber((element.weight / 100) * costRule, "currency")
            : "Na"}
        </Table.Td>
      </Table.Tr>
    ))
    return (
      <>
        <Table
          onClick={() => (openModalSplitCost ? openModalSplitCost() : null)}
        >
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Label</Table.Th>
              <Table.Th>Percentage</Table.Th>
              <Table.Th>Estimated Cost</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </>
    )
  }
  if (allocation.reAllocationParams.type == "externalTelemetry") {
    return <></>
  }
  if (allocation.reAllocationParams.type == "relative") {
    return <></>
  }
}
