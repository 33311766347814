import { useParams } from "react-router-dom"

import { DashboardLayout } from "@costory/front/components/dashboard/DashboardLayout"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useDashboardQuery } from "@costory/front/queries/dashboard"
import { useListSavedViewsQuery } from "@costory/front/queries/savedViews"

export const HomePage = () => {
  const { dashboardId } = useParams()
  const savedViewQuery = useListSavedViewsQuery()
  const dashboardQuery = useDashboardQuery(dashboardId)

  return (
    <QueryWrapper query={dashboardQuery} allowEmptyArray>
      {({ data: dashboard }) => (
        <QueryWrapper query={savedViewQuery} allowEmptyArray>
          {({ data: savedViews }) => (
            <DashboardLayout
              savedViews={savedViews}
              currentDashboard={dashboard}
            />
          )}
        </QueryWrapper>
      )}
    </QueryWrapper>
  )
}
