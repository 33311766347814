import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import {
  DashboardRequests,
  DashboardResponses,
} from "@costory/types/endpoints/dashboard"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useDashboardQuery = (dashboardId?: string) => {
  return useQuery({
    queryKey: ["dashboard", dashboardId],
    queryFn: async () => {
      const response = await apiClient.get<DashboardResponses.DashboardT>(
        dashboardId ? `/dashboard/${dashboardId}` : "/dashboard",
      )
      return response.data
    },
  })
}

export const useDashboardListQuery = () => {
  return useQuery({
    queryKey: ["dashboardList"],
    queryFn: async () => {
      const response =
        await apiClient.get<DashboardResponses.DashboardT[]>("/dashboard/list")
      return response.data
    },
  })
}

export const useCreateDashboardMutation = (onSuccess: () => void) => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (dash: DashboardRequests.NewDashboard) => {
      const response = await apiClient.post<DashboardResponses.DashboardT>(
        "/dashboard",
        dash,
      )
      return response.data
    },
    onSuccess: async (dash) => {
      onSuccess()
      await queryClient.invalidateQueries({ queryKey: ["dashboardList"] })
      showNotification({
        color: "green",
        title: "Dashboard saved !",
        message: "Dashboard saved successfully",
      })
      navigate(`/home/dashboard/${dash.id}`)
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.message,
      })
    },
  })
}

export const useUpdateDashboardMutation = (onSuccess: () => void) => {
  return useMutation({
    mutationFn: async (dash: DashboardRequests.UpdateDashboard) => {
      const response = await apiClient.put<DashboardResponses.DashboardT>(
        `/dashboard/${dash.id}`,
        dash,
      )
      return response.data
    },
    onSuccess: async (data: DashboardResponses.DashboardT) => {
      onSuccess()
      await queryClient.invalidateQueries({
        queryKey: ["dashboard", data.id],
      })
      await queryClient.invalidateQueries({
        queryKey: ["dashboardList"],
      })
      showNotification({
        color: "green",
        title: "Dashboard saved !",
        message: "Dashboard saved successfully ",
      })
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.message,
      })
    },
  })
}
