import { useQuery } from "@tanstack/react-query"

import { MetricsResponses } from "@costory/types/endpoints/metrics"

import { apiClient } from "@costory/front/lib/apiClient"

export const useBusinessMetricsQuery = () => {
  return useQuery({
    queryKey: ["metrics"],
    queryFn: async () => {
      const response =
        await apiClient.get<MetricsResponses.Metric[]>("/metrics")
      return response.data
    },
  })
}
