import { Button, Group, Input, Select, Stack, Text } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { z } from "zod"

import { CHART_TYPES } from "@costory/types/endpoints/dashboard"
import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"

import { Chart } from "@costory/front/components/charts/Chart"
import { ChartTypeSelector } from "@costory/front/components/dashboard/ChartTypeSelector"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useListSavedViewsQuery } from "@costory/front/queries/savedViews"

const addWidgetSchema = z.object({
  title: z.string(),
  viewId: z.string(),
  chartType: z.enum(CHART_TYPES),
})

export type AddWidgetForm = z.infer<typeof addWidgetSchema>

type Props = {
  onAdd: (widgetConfig: AddWidgetForm) => void
  savedViews: SavedViewsResponses.SavedView[]
}

const _AddWidget = ({ savedViews, onAdd }: Props) => {
  const form = useForm<AddWidgetForm>({
    validate: zodResolver(addWidgetSchema),
  })

  return (
    <Stack gap={32}>
      <Stack gap={8}>
        <Text fw="bold">1. Choose the view to display</Text>
        <Select
          searchable
          {...form.getInputProps("viewId")}
          placeholder="Select a saved view"
          data={savedViews.map((view) => ({
            label: view.name,
            value: view.id,
          }))}
          onChange={(value, option) => {
            if (!value) return
            form.setValues({ viewId: value, title: option.label })
          }}
        />
      </Stack>
      <Stack gap={8}>
        <Text fw="bold">2. Choose the visualization</Text>
        <ChartTypeSelector {...form.getInputProps("chartType")} />
      </Stack>
      {form.values.viewId && form.values.chartType && (
        <Stack
          style={({ colors }) => ({
            borderTop: `1px solid ${colors.gray[1]}`,
          })}
          pt={24}
        >
          <Text fw="bold" fz="lg">
            Chart Preview
          </Text>
          <Group w="100%" wrap="nowrap">
            <Stack w="60%" align="center" style={{ zIndex: 1000 }}>
              <Chart
                chartType={form.values.chartType}
                height={250}
                filters={
                  savedViews.find((view) => view.id === form.values.viewId)!
                }
                withLegend={false}
              />
            </Stack>
            <Stack
              w="40%"
              h="100%"
              align="center"
              justify="center"
              gap={24}
              p={24}
              style={({ colors }) => ({
                borderLeft: `1px solid ${colors.gray[1]}`,
              })}
            >
              <Stack gap={8} w="100%">
                <Text fw="bold">3. Name your widget</Text>
                <Input {...form.getInputProps("title")} />
              </Stack>
              <Text ta="center">
                You can change the parameters before adding the widget to your
                dashboard
              </Text>
              <Button size="lg" onClick={() => onAdd(form.values)}>
                Add to Dashboard
              </Button>
            </Stack>
          </Group>
        </Stack>
      )}
    </Stack>
  )
}

export const AddWidget = ({ onAdd }: Pick<Props, "onAdd">) => {
  const savedViewsQuery = useListSavedViewsQuery()

  return (
    <QueryWrapper
      query={savedViewsQuery}
      emptyAlert={{
        title: "No saved views",
        message:
          "You don't have any view saved for now, go to the Explorer and create your first view!",
      }}
    >
      {({ data }) => <_AddWidget onAdd={onAdd} savedViews={data} />}
    </QueryWrapper>
  )
}
