import { Button, Group, Stack, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import {
  IconCopy,
  IconPencil,
  IconPlus,
  IconRefresh,
  IconTrash,
} from "@tabler/icons-react"
import {
  createMRTColumnHelper,
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import { VirtualDimensionsResponses } from "@costory/types/endpoints/virtualDimensions"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import {
  VirtualDimensionComputeStatusCell,
  VirtualDimensionStatusCell,
} from "@costory/front/components/table/VirtualDimensionStatusCell"
import { ModalOpenVersions } from "@costory/front/pages/VirtualDimensions/components/ModalOpenVersion"
import {
  useDeleteVirtualDim,
  useDuplicateVirtualDimensionMutation,
  useListVirtualDimensionsQuery,
} from "@costory/front/queries/virtualDimensions"
import { getDefaultTableOptions } from "@costory/front/utils/table"

type Props = {
  virtualDimensions: VirtualDimensionsResponses.VirtualDimension[]
}

const VirtualDimensions = ({ virtualDimensions }: Props) => {
  const { mutateAsync: duplicateVirtualDim, isPending: isPendingDuplication } =
    useDuplicateVirtualDimensionMutation()
  const { mutateAsync: deleteVirtualDim, isPending: isPendingDeletion } =
    useDeleteVirtualDim()

  const openModal = (id: string, name: string, lastUser: string) =>
    modals.openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          Please confirm the deletion of {name} last edited by {lastUser} ?{" "}
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onConfirm: () => deleteVirtualDim(id),
    })
  const navigate = useNavigate()
  function onZoom(row: VirtualDimensionsResponses.VirtualDimension) {
    setSelectedRow(row)
    open()
  }
  const [opened, { open, close }] = useDisclosure(false)
  const [selectedRow, setSelectedRow] =
    useState<VirtualDimensionsResponses.VirtualDimension>()

  const columns = useMemo<
    MRT_ColumnDef<VirtualDimensionsResponses.VirtualDimension>[]
  >(() => {
    const columnHelper =
      createMRTColumnHelper<VirtualDimensionsResponses.VirtualDimension>()
    return [
      columnHelper.accessor("name", {
        header: "Name",
        filterVariant: "autocomplete",
      }),
      columnHelper.accessor("status", {
        header: "Status",
        filterVariant: "select",
        Cell: ({ cell }) => (
          <VirtualDimensionStatusCell status={cell.getValue()} />
        ),
      }),
      columnHelper.accessor("computeStatus", {
        header: "Compute Status",
        filterVariant: "select",
        Cell: ({ cell }) => (
          <VirtualDimensionComputeStatusCell status={cell.getValue()!} />
        ),
      }),
      columnHelper.display({
        header: "Actions",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Group gap={8} wrap="nowrap">
            <Button
              size="xs"
              variant="outline"
              leftSection={<IconPencil size={16} />}
              onClick={() => onZoom(row.original)}
            >
              Open Versions
            </Button>
            <Button
              size="xs"
              variant="outline"
              disabled={row.original.status == "DRAFT"}
              leftSection={<IconPencil size={16} />}
              onClick={() => navigate(`/virtual-dimensions/${row.original.id}`)}
            >
              Edit
            </Button>
            <Button
              size="xs"
              variant="outline"
              leftSection={<IconCopy size={16} />}
              loading={isPendingDuplication}
              onClick={() =>
                duplicateVirtualDim({
                  id: row.original.id,
                })
              }
            >
              Duplicate
            </Button>
            <Button
              size="xs"
              variant="outline"
              color="red"
              disabled={row.original.usedByVirtualDimensions.length > 0}
              loading={isPendingDeletion}
              onClick={() =>
                openModal(
                  row.original.id,
                  row.original.name,
                  row.original.VirtualDimensionVersions[0].createdBy.id,
                )
              }
              leftSection={<IconTrash size={16} />}
            >
              Delete
            </Button>
          </Group>
        ),
      }),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  const table =
    useMantineReactTable<VirtualDimensionsResponses.VirtualDimension>({
      columns,
      data: virtualDimensions,
      ...getDefaultTableOptions(),
    })

  return (
    <Stack gap={32}>
      <Group justify="flex-end">
        <Button
          variant="outline"
          leftSection={<IconRefresh />}
          onClick={() => navigate("job-executions")}
        >
          Compute rule results
        </Button>
        <Button component={Link} to="new" leftSection={<IconPlus />}>
          New
        </Button>
      </Group>
      <MantineReactTable table={table} />
      <ModalOpenVersions
        opened={opened}
        onClose={close}
        data={selectedRow ? selectedRow.VirtualDimensionVersions : []}
      />
    </Stack>
  )
}

export const VirtualDimensionsPage = () => {
  const virtualDimensionsQuery = useListVirtualDimensionsQuery()
  return (
    <QueryWrapper query={virtualDimensionsQuery} allowEmptyArray>
      {({ data }) => <VirtualDimensions virtualDimensions={data} />}
    </QueryWrapper>
  )
}
