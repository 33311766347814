import { Text } from "@mantine/core"

import { formatNumber, NumberType } from "@costory/front/utils/format"

type Props = {
  value: number
  numberType?: NumberType
  disableColor?: boolean
}

export const NumberCell = ({
  value,
  numberType = "currency",
  disableColor = false,
}: Props) => (
  <Text
    size="sm"
    c={
      !disableColor && numberType === "percent"
        ? value < 0
          ? "green.6"
          : "red.6"
        : "inherit"
    }
  >
    {formatNumber(value, numberType)}
  </Text>
)
