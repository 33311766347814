import { Allocation } from "@costory/types/endpoints/virtualDimensions"

type AllocationTypeInfo = {
  label: string
  value: Allocation["allocationType"]
  disabled?: boolean
}

export const ALLOCATION_TYPES: AllocationTypeInfo[] = [
  {
    label: "Dimension value",
    value: "dimensionValue",
  },
  {
    label: "Existing dimension",
    value: "existingColumn",
  },
  {
    label: "Split cost",
    value: "splitCost",
  },
]
