const COLORS_FOR_PALETTE = ["primary", "blue", "red", "orange"] as const
const SHADES_FOR_PALETTE = [5, 3] as const

/**
 * Generate color palette for charts
 * Result for ["a", "b"] will be ["a.5", "b.3", "a.3", "b.5"]
 */
export const COLOR_PALETTE = COLORS_FOR_PALETTE.map(
  (color, index) => `${color}.${SHADES_FOR_PALETTE[index % 2]}`,
).concat(
  COLORS_FOR_PALETTE.map(
    (color, index) => `${color}.${SHADES_FOR_PALETTE[(index + 1) % 2]}`,
  ),
)

export const getCharteSerieColor = (index: number) => {
  return COLOR_PALETTE[index % COLOR_PALETTE.length]
}

export interface PayloadItem {
  className?: string
  style?: React.CSSProperties
  name: string
  fill?: string
  stroke?: string
  fillOpacity?: number
  strokeOpacity?: number
  dataKey: string
  color: string
  value: [number, number]
  payload: {
    cost: [number, number]
    name: string
    color: string
    fill?: string
  }
  hide?: boolean
}
export const truncateLabel = (label: string, maxLength: number) => {
  return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label
}
