import { Button, Group, Modal, Stack, Tabs, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import {
  reAllocationParamsType,
  VirtualDimensionsRequests,
} from "@costory/types/endpoints/virtualDimensions"

import { CustomReallocation } from "@costory/front/pages/VirtualDimensions/components/CustomReallocation"

type Props = {
  isOpen: boolean
  onClose: () => void
  form: UseFormReturnType<VirtualDimensionsRequests.EditVirtualDimension>
  currentSettings?: reAllocationParamsType
  costRule: number | undefined
  prefixForm: string
  allocation: VirtualDimensionsRequests.EditVirtualDimension["rules"][0]["allocation"]
}

export const SplitCostModal = ({
  onClose,
  isOpen,
  form,
  costRule,
  prefixForm,
  allocation,
}: Props) => {
  return (
    <Modal
      size="xl"
      opened={isOpen}
      onClose={onClose}
      title="Split the cost"
      closeOnClickOutside={false}
      withCloseButton={false}
    >
      <Tabs
        color="gray"
        variant="outline"
        radius="xl"
        orientation="vertical"
        styles={{ panel: { padding: 16 } }}
        {...form.getInputProps(`${prefixForm}.reAllocationParams.type`)}
      >
        <Tabs.List>
          <Tabs.Tab value="custom">Manual reallocation</Tabs.Tab>
          <Tabs.Tab value="relative">Relative Cost reallocation</Tabs.Tab>
          <Tabs.Tab value="externalTelemetry">
            Telemetry Based reallocation
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="custom">
          <Stack>
            <CustomReallocation
              form={form}
              costRule={costRule}
              prefixForm={prefixForm}
              allocation={allocation}
            />
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="relative">
          <Text>
            To be released soon: in relative cost reallocation we will every day
            compute the allocation ratio for each value over all previous rules
            and reapply it on this rule. Objective is to reallocate 100% of the
            cost even if not perfect
          </Text>
        </Tabs.Panel>

        <Tabs.Panel value="externalTelemetry">
          <Text>
            To be released soon: Every day we will ingest a metric composed of a
            key and a value and reapply this ratio on the cost.
          </Text>
        </Tabs.Panel>
      </Tabs>
      <Group justify="flex-end">
        <Button
          variant="outline"
          onClick={() => {
            if (
              form.validateField(`${prefixForm}.reAllocationParams`).hasError
            ) {
              form.setFieldValue(
                `${prefixForm}.allocationType`,
                "dimensionValue",
              )
            }
            onClose()
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={
            form.validateField(`${prefixForm}.reAllocationParams`).hasError
          }
          onClick={onClose}
        >
          Next
        </Button>
      </Group>
    </Modal>
  )
}
