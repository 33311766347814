import {
  Badge,
  Button,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconPlus, IconX } from "@tabler/icons-react"
import { useState } from "react"

import { VirtualDimensionsRequests } from "@costory/types/endpoints/virtualDimensions"

type Props = {
  form: UseFormReturnType<VirtualDimensionsRequests.EditVirtualDimension>
  isOpen: boolean
  onClose: () => void
}

export const DimensionValuesModal = ({ form, isOpen, onClose }: Props) => {
  const values = form.getValues().values
  const [val, setVal] = useState("")

  const handleAddValue = (v: string) => {
    if (!values.includes(v)) {
      form.insertListItem("values", v, 0)
      setVal("")
    }
  }

  return (
    <Modal
      size="xl"
      opened={isOpen}
      onClose={onClose}
      title="Create a new Dimension"
    >
      <Group
        align="flex-start"
        wrap="nowrap"
        style={({ colors }) => ({
          borderTop: `1px solid ${colors.gray[1]}`,
        })}
        py={32}
      >
        <Stack gap={24} w="40%">
          <Stack gap={8}>
            <Text fw="bold">What is it for?</Text>
            <Text>
              Dimensions will be built thanks to the team work between your Tech
              & Finance champions. Build rules using SQL queries & shared cost
              breakdown to make you cloud cost meaningful.
            </Text>
          </Stack>
          <Stack gap={8}>
            <Badge>Dimension</Badge>
            <Text>
              1n axis to breakdown your cloud cost. You will probably have
              multiple dimensions. Common examples: Teams, Environment,
              Product_Lines...
            </Text>
          </Stack>
          <Stack gap={8}>
            <Badge>Dimension Values</Badge>
            <Text>
              The values that make sense for your org and you aim to report on
              to. The sum of your dimension values equal 100% of your cloud
            </Text>
          </Stack>
        </Stack>
        <Divider orientation="vertical" color="gray.1" />
        <Stack w="60%">
          <TextInput
            label="Write your own dimension name"
            {...form.getInputProps("name")}
          />
          <Text fw="bold" mt={16}>
            Dimension values
          </Text>
          {values.map((value, index) => (
            <Group key={`value-${index}`} gap={8}>
              <TextInput
                disabled={true}
                {...form.getInputProps(`values.${index}`)}
                flex={1}
                value={value}
              />
              <ThemeIcon
                color="gray"
                onClick={() => form.removeListItem("values", index)}
              >
                <IconX />
              </ThemeIcon>
            </Group>
          ))}
          <TextInput
            value={val}
            onChange={(v) => setVal(v.target.value)}
            flex={1}
          />
          <Button
            size="sm"
            variant="outline"
            leftSection={<IconPlus size={20} />}
            onClick={() => handleAddValue(val)}
          >
            Add a value
          </Button>
        </Stack>
      </Group>
      <Group justify="flex-end">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onClose}>Next</Button>
      </Group>
    </Modal>
  )
}
