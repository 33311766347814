import { Button } from "@mantine/core"
import {
  createMRTColumnHelper,
  useMantineReactTable,
  MantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"

import { VirtualDimensionsResponses } from "@costory/types/endpoints/virtualDimensions"

import { UNLABELLED } from "@costory/shared/utils/explorer_data"

import { NumberCell } from "@costory/front/components/table/NumberCell"
import {
  CURRENCY_FILTER_VARIANT,
  getDefaultTableOptions,
} from "@costory/front/utils/table"

type Props = {
  data: VirtualDimensionsResponses.SheetExplorerData["groupedByValue"]
  preFillData: (values: string[]) => void
}

export const TableChartZoom = ({ data, preFillData }: Props) => {
  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<
        VirtualDimensionsResponses.SheetExplorerData["groupedByValue"][0]
      >()
    return [
      columnHelper.accessor("groupByValue", {
        header: "Value",
        filterVariant: "select",
      }),
      columnHelper.accessor("periodCost", {
        header: "Cost",
        ...CURRENCY_FILTER_VARIANT,
        Cell: ({ cell }) => <NumberCell value={cell.getValue()} />,
      }),
    ]
  }, [])

  const table = useMantineReactTable<
    VirtualDimensionsResponses.SheetExplorerData["groupedByValue"][0]
  >({
    enableRowSelection: (row) => row.original.groupByValue != UNLABELLED,
    selectAllMode: "all",
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onClick={() => {
          const selectedRows = table.getSelectedRowModel().rows //or read entire rows
          preFillData(selectedRows.map((elem) => elem.original.groupByValue))
        }}
      >
        Prefill
      </Button>
    ),
    columns,
    data,
    ...getDefaultTableOptions(true),
    initialState: {
      sorting: [{ id: "periodCost", desc: true }],
    },
  })

  return <MantineReactTable table={table} />
}
