import {
  Checkbox,
  PasswordInput,
  Select,
  TagsInput,
  TextInput,
} from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form"
import { useState } from "react"

import { DatasourcesRequests } from "@costory/types/endpoints/datasources"

import { DEFAULT_DATE_FORMAT, getDayJs } from "@costory/shared/utils/dates"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useListDatasourcesQuery } from "@costory/front/queries/datasources"

type Props = {
  form: UseFormReturnType<DatasourcesRequests.EditDatasource>
}

export const MetadataForm = ({ form }: Props) => {
  const [restrictStart, setRestrictStart] = useState(
    "startDate" in form.getValues().metadata,
  )
  const [restrictEnd, setRestrictEnd] = useState(
    "endDate" in form.getValues().metadata,
  )
  const datasourceQuery = useListDatasourcesQuery()

  if (!form.getValues().type) return null

  switch (form.getValues().type) {
    case "aws/cur":
      return (
        <QueryWrapper query={datasourceQuery} allowEmptyArray>
          {({ data }) => (
            <>
              <Select
                {...form.getInputProps("metadata.bucket")}
                key={form.key("metadata.bucket")}
                label="Bucket"
                data={data
                  .filter((elem) => elem.connector === "S3Bucket")
                  .map((elem) => ({ label: elem.name, value: elem.id }))}
              />
              <TextInput
                label="S3 prefix"
                placeholder="prefix"
                {...form.getInputProps("metadata.prefix")}
                key={form.key("metadata.prefix")}
              />
              <Checkbox
                label="EKS Split data enabled"
                checked={form.getInputProps("metadata.eksSplit").value}
                defaultChecked={
                  form.getInputProps("metadata.eksSplit").defaultValue
                }
                onChange={form.getInputProps("metadata.eksSplit").onChange}
                key={form.key("metadata.eksSplit")}
              />
              <Checkbox
                checked={restrictStart}
                onChange={(event) =>
                  setRestrictStart(event.currentTarget.checked)
                }
                label="Restrict data start date"
              />
              <DatePickerInput
                label="Start date"
                disabled={!restrictStart}
                placeholder={getDayJs()().format(DEFAULT_DATE_FORMAT)}
                {...form.getInputProps("metadata.startDate")}
                key={form.key("metadata.startDate")}
              />
              <Checkbox
                checked={restrictEnd}
                onChange={(event) =>
                  setRestrictEnd(event.currentTarget.checked)
                }
                label="Restrict data end date"
              />
              <DatePickerInput
                label="End date"
                disabled={!restrictEnd}
                placeholder={getDayJs()().format(DEFAULT_DATE_FORMAT)}
                {...form.getInputProps("metadata.endDate")}
                key={form.key("metadata.endDate")}
              />
              <TagsInput
                label="Axes"
                placeholder="Enter axes"
                {...form.getInputProps("metadata.axes")}
                key={form.key("metadata.axes")}
              />
            </>
          )}
        </QueryWrapper>
      )
    case "gcp/detailed_billing":
      return (
        <>
          <TextInput
            label="BigQuery Dataset Location"
            placeholder="<yourProjectId>.<dataset>.<table_name>"
            {...form.getInputProps("metadata.billingLocation")}
            key={form.key("metadata.billingLocation")}
          />
          <Checkbox
            label="Is Detailed Billing"
            checked={form.getInputProps("metadata.isDetailed").value}
            defaultChecked={
              form.getInputProps("metadata.isDetailed").defaultValue
            }
            onChange={form.getInputProps("metadata.isDetailed").onChange}
            key={form.key("metadata.isDetailed")}
          />
          <Checkbox
            checked={restrictStart}
            onChange={(event) => setRestrictStart(event.currentTarget.checked)}
            label="Restrict data start date"
          />
          <DatePickerInput
            label="Start date"
            disabled={!restrictStart}
            placeholder={getDayJs()().format(DEFAULT_DATE_FORMAT)}
            {...form.getInputProps("metadata.startDate")}
            key={form.key("metadata.startDate")}
          />
          <Checkbox
            checked={restrictEnd}
            onChange={(event) => setRestrictEnd(event.currentTarget.checked)}
            label="Restrict data end date"
          />
          <DatePickerInput
            label="End date"
            disabled={!restrictEnd}
            placeholder={getDayJs()().format(DEFAULT_DATE_FORMAT)}
            {...form.getInputProps("metadata.endDate")}
            key={form.key("metadata.endDate")}
          />
          <TagsInput
            label="Axes"
            placeholder="Enter axes"
            {...form.getInputProps("metadata.axes")}
            key={form.key("metadata.axes")}
          />
        </>
      )
    case "gsheet/business_metrics":
      return (
        <>
          <Select
            {...form.getInputProps("metadata.periodRange")}
            key={form.key("metadata.periodRange")}
            label="Period definition"
            data={["month", "day"]}
          />
          <TagsInput
            label="Metrics"
            placeholder="Enter metrics"
            {...form.getInputProps("metadata.metrics")}
            key={form.key("metadata.metrics")}
          />
          <TextInput
            label="Email"
            placeholder="Enter email to share the drive to"
            {...form.getInputProps("metadata.email")}
            key={form.key("metadata.email")}
          />
        </>
      )
    case "datadog":
      return (
        <>
          <TextInput
            label="datadog connection name"
            placeholder="Enter datadog connection name"
            {...form.getInputProps("metadata.name")}
            key={form.key("metadata.name")}
          />
        </>
      )
    case "decorator/bigquery":
      return (
        <>
          <TextInput
            label="column to join the data on"
            placeholder="column to join"
            {...form.getInputProps("metadata.joinKey")}
            key={form.key("metadata.joinKey")}
          />
          <TextInput
            label="Data Location"
            placeholder="data location"
            {...form.getInputProps("metadata.bqLocation")}
            key={form.key("metadata.bqLocation")}
          />
          <TagsInput
            label="Columns"
            placeholder="Columns"
            {...form.getInputProps("metadata.columns")}
            key={form.key("metadata.columns")}
          />
        </>
      )
    case "aws/s3_bucket":
      return (
        <>
          <TextInput
            label="Bucket Name"
            placeholder="Enter Bucket Name"
            {...form.getInputProps("metadata.bucketName")}
            key={form.key("metadata.bucketName")}
          />
          <TextInput
            label="Region"
            placeholder="Enter region"
            {...form.getInputProps("metadata.region")}
            key={form.key("metadata.region")}
          />
          <TextInput
            label="AWS Key"
            placeholder="Enter AWS Key"
            {...form.getInputProps("metadata.awsKey")}
            key={form.key("metadata.awsKey")}
          />
          <PasswordInput
            label="AWS Password"
            placeholder="Enter AWS Password"
            {...form.getInputProps("metadata.awsPrivateKey")}
            key={form.key("metadata.awsPrivateKey")}
          />
        </>
      )
  }
}
