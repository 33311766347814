import { Button, Group, Modal, Stack, Title } from "@mantine/core"
import { IconPencil } from "@tabler/icons-react"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { VirtualDimensionsResponses } from "@costory/types/endpoints/virtualDimensions"

import { getDefaultTableOptions } from "@costory/front/utils/table"

export function ModalOpenVersions({
  opened,
  onClose,
  data,
}: {
  opened: boolean
  onClose: () => void
  data: VirtualDimensionsResponses.VirtualDimension["VirtualDimensionVersions"]
}) {
  const navigate = useNavigate()
  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<
        VirtualDimensionsResponses.VirtualDimension["VirtualDimensionVersions"][0]
      >()
    return [
      columnHelper.accessor("description", {
        header: "Name",
        filterVariant: "autocomplete",
      }),
      columnHelper.accessor("createdBy.firstName", {
        header: "Created By",
        filterVariant: "autocomplete",
        Cell: ({ row }) =>
          `${row.original.createdBy.firstName} ${row.original.createdBy.lastName}`,
      }),
      columnHelper.accessor("createdAt", {
        header: "Created At",
        filterVariant: "autocomplete",
      }),
      columnHelper.accessor("isCompleted", {
        header: "Status",
        Cell: ({ cell }) => (cell.getValue() ? "Live" : "Draft"),
      }),
      columnHelper.display({
        header: "Actions",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Group wrap="nowrap">
            <Button
              size="xs"
              variant="outline"
              leftSection={<IconPencil size={16} />}
              onClick={() =>
                navigate(
                  `/virtual-dimensions/${row.original.virtualDimensionId}/draft/${row.original.id}`,
                )
              }
            >
              Open
            </Button>
          </Group>
        ),
      }),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const table = useMantineReactTable<
    VirtualDimensionsResponses.VirtualDimension["VirtualDimensionVersions"][0]
  >({
    columns,
    data,
    ...getDefaultTableOptions(),
    initialState: {
      sorting: [{ id: "createdAt", desc: true }],
    },
  })
  if (data) {
    return (
      <Modal opened={opened} onClose={onClose} size="100%">
        <Stack>
          <Title> All Versions</Title>
          <MantineReactTable table={table} />
        </Stack>
      </Modal>
    )
  }
}
