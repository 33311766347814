import { Group, ThemeIcon, Text } from "@mantine/core"
import {
  IconArrowDownRight,
  IconArrowRight,
  IconArrowUpRight,
} from "@tabler/icons-react"
import { useMemo } from "react"

import { formatNumber } from "@costory/front/utils/format"

type Props = {
  current: number
  previous: number
}

export const TrendBadge = ({ current, previous }: Props) => {
  const ratio = useMemo(() => {
    if (previous !== 0) return (current - previous) / previous
    return undefined
  }, [current, previous])

  const badgeProps = useMemo(() => {
    if (!ratio) {
      return {
        color: "orange",
        icon: IconArrowRight,
      }
    }
    if (ratio > 0) {
      return {
        color: "red",
        icon: IconArrowUpRight,
      }
    }
    return {
      color: "green",
      icon: IconArrowDownRight,
    }
  }, [ratio])

  if (ratio == null)
    return (
      <Group
        justify="space-between"
        pl={8}
        pr={8}
        py={2}
        bg="gray.1"
        wrap="nowrap"
        gap={4}
        style={{
          borderRadius: 4,
          border: "1px solid gray",
        }}
      >
        <Text fz="sm" c="gray.7">
          {"\u221e"}
        </Text>
      </Group>
    )
  return (
    <Group
      justify="space-between"
      pl={4}
      pr={8}
      py={2}
      bg={`${badgeProps.color}.1`}
      wrap="nowrap"
      gap={4}
      style={({ colors }) => ({
        borderRadius: 4,
        border: `1px solid ${colors[badgeProps.color][4]}`,
      })}
    >
      <ThemeIcon size="sm" color={`${badgeProps.color}.7`}>
        <badgeProps.icon />
      </ThemeIcon>
      <Text fz="sm" c={`${badgeProps.color}.7`}>
        {formatNumber(ratio, "percent", 1)}
      </Text>
    </Group>
  )
}
