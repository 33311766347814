import { Box, Button, Stack, Text, Title } from "@mantine/core"
import { useQuery } from "@tanstack/react-query"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import qs from "qs"
import { useMemo } from "react"
import { RuleGroupType, formatQuery } from "react-querybuilder"
import { useNavigate } from "react-router-dom"

import { InsightsResponses } from "@costory/types/endpoints/insights"

import { computePresetDates } from "@costory/shared/utils/filters"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { InsightsCustomTooltip } from "@costory/front/components/table/InsightsCustomTooltip"
import { NumberCell } from "@costory/front/components/table/NumberCell"
import { apiClient } from "@costory/front/lib/apiClient"
import { FocusCols, getBestDrillDown } from "@costory/front/utils/columns"
import { formatNumber } from "@costory/front/utils/format"
import { getDefaultTableOptions } from "@costory/front/utils/table"

type Props = {
  insights: InsightsResponses.Insight[]
}

const calculateMinMax = (
  data: InsightsResponses.Insight[],
  key: keyof InsightsResponses.Insight,
) => {
  const values = data
    .map((item) => item[key])
    .filter((value): value is number => typeof value === "number")

  return {
    min: Math.min(...values) - 1,
    max: Math.max(...values) + 2,
  }
}

// Function to create a filter URL based on the key and value selected
function generateFiltersFromInsights(key: FocusCols, value: string) {
  // Creating a default params to pass to Explorer page as filters
  const tempParams = {
    metricId: "cost",
    limit: 3,
    // Used "getBestDrillDown" to get the Group by based on the selected key
    groupBy: getBestDrillDown(key),
    // Used computePresetDates to get the "from", "to", "previousFrom", "previousTo" and "aggBy" params
    ...computePresetDates("LAST_6_MONTHS"),
    // Create a where clause with "key" as field and "value" as the value array
    whereClause: encodeURIComponent(
      formatQuery(
        {
          combinator: "and",
          rules: [
            {
              field: key as string,
              operator: "in",
              valueSource: "value",
              value: [value],
            },
          ],
        } as RuleGroupType,
        "json_without_ids",
      ),
    ),
  }

  // Converting the params to a query string to pass in the URL
  const queryString = qs.stringify(tempParams, {
    encode: false,
  })
  return queryString
}

const Insights = ({ insights }: Props) => {
  const navigate = useNavigate()
  const ranges = useMemo(() => {
    const rangeKeys = [
      "baseline",
      "diffLastTwoMonths",
      "diffLastSixMonths",
    ] as const

    return rangeKeys.reduce(
      (acc, key) => {
        acc[key] = calculateMinMax(insights, key)
        return acc
      },
      {} as Record<
        (typeof rangeKeys)[number],
        ReturnType<typeof calculateMinMax>
      >,
    )
  }, [insights])

  const {
    baseline: baselineRange,
    diffLastTwoMonths: diffLastTwoMonthsRange,
    diffLastSixMonths: diffLastSixMonthsRange,
  } = ranges

  const columns = useMemo(() => {
    const columnHelper = createMRTColumnHelper<InsightsResponses.Insight>()
    return [
      columnHelper.accessor("dimension", {
        header: "Dimension",
        filterVariant: "select",
      }),
      columnHelper.accessor("value", {
        header: "Value",
        filterVariant: "autocomplete",
        size: 10,
        Cell: ({ cell, row }) => {
          return (
            <Button
              bd="none"
              bg="transparent"
              c="inherit"
              ta="left"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                const queryString = generateFiltersFromInsights(
                  row.original.name,
                  row.original.value,
                )
                navigate(`/explorer?${queryString}`)
              }}
            >
              {cell.getValue()}
            </Button>
          )
        },
      }),
      columnHelper.accessor("baseline", {
        header: "Last Month",
        filterVariant: "range-slider",
        mantineFilterRangeSliderProps: {
          min: baselineRange.min,
          max: baselineRange.max,
          label: formatNumber,
        },
        Cell: ({ cell }) => <NumberCell value={cell.getValue()} />,
      }),
      columnHelper.accessor("diffLastTwoMonths", {
        header: "vs. Last 2 Months",
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        mantineFilterRangeSliderProps: {
          min: diffLastTwoMonthsRange.min,
          max: diffLastTwoMonthsRange.max,
          label: formatNumber,
        },
        Cell: ({ cell, row }) => (
          <InsightsCustomTooltip isVsTwoMonths={true} data={row.original}>
            <Box
              c={cell.getValue() < 0 ? "green.6" : "red.6"}
              style={{ cursor: "pointer" }}
            >
              <NumberCell value={cell.getValue()} />
            </Box>
          </InsightsCustomTooltip>
        ),
      }),
      columnHelper.accessor("relativeTwoMonths", {
        header: "Relative 2 Months",
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} numberType="percent" />
        ),
      }),
      columnHelper.accessor("diffLastSixMonths", {
        header: "vs. Last 6 Months",
        filterFn: "betweenInclusive",
        filterVariant: "range-slider",
        mantineFilterRangeSliderProps: {
          min: diffLastSixMonthsRange.min,
          max: diffLastSixMonthsRange.max,
          label: formatNumber,
        },
        Cell: ({ cell, row }) => (
          <InsightsCustomTooltip isVsTwoMonths={false} data={row.original}>
            <Box
              c={cell.getValue() < 0 ? "green.6" : "red.6"}
              style={{ cursor: "pointer" }}
            >
              <NumberCell value={cell.getValue()} />
            </Box>
          </InsightsCustomTooltip>
        ),
      }),
      columnHelper.accessor("relativeSixMonths", {
        header: "Relative 6 Months",
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} numberType="percent" />
        ),
      }),
    ]
  }, [navigate, baselineRange, diffLastTwoMonthsRange, diffLastSixMonthsRange])

  const table = useMantineReactTable<InsightsResponses.Insight>({
    columns,
    data: insights,
    ...getDefaultTableOptions(true),
    initialState: {
      sorting: [
        {
          id: "diffLastTwoMonths",
          desc: true,
        },
      ],
    },
  })

  return (
    <Stack>
      <Stack gap={2}>
        <Title>Insights</Title>
        <Text>
          Compare the cost of last month, in comparison to the rolling average
          of the past
        </Text>
      </Stack>
      <MantineReactTable table={table} />
    </Stack>
  )
}

export const InsightsPage = () => {
  const insightsQuery = useQuery({
    queryKey: ["insights"],
    queryFn: async () => {
      const response =
        await apiClient.get<InsightsResponses.Insight[]>("/insights")
      return response.data
    },
  })

  return (
    <QueryWrapper query={insightsQuery}>
      {({ data }) => <Insights insights={data} />}
    </QueryWrapper>
  )
}
