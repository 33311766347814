import _ from "lodash"

import { DashboardResponses } from "@costory/types/endpoints/dashboard"

export const orderComponentTypes = (value: string) => {
  switch (value) {
    case "Private": {
      return 1
    }
    case "My Public": {
      return 2
    }
    case "Other Public": {
      return 3
    }
    default: {
      return 4
    }
  }
}
export const formatDashboardsList = (
  dashboards: DashboardResponses.DashboardT[],
) => {
  return _(dashboards)
    .groupBy("category")
    .map((v, k) => ({
      group: k,
      items: v.map((sv) => ({
        value: sv.id!,
        label: sv.name!,
      })),
    }))
    .sortBy((g) => orderComponentTypes(g.group))
    .value()
}
