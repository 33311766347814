import { Drawer, Select, Stack } from "@mantine/core"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { useMemo, useState } from "react"
import { RuleGroupType } from "react-querybuilder"

import { AxesResponses } from "@costory/types/endpoints/axes"

import { TableChartZoom } from "@costory/front/components/charts/TableExplorerZoom"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { useExplorerDrawer } from "@costory/front/queries/explore"
import { getBestDrillDown } from "@costory/front/utils/columns"
import { formatDatasources } from "@costory/front/utils/filters"

type Props = {
  opened: boolean
  index: number
  close: () => void
  rules: { query: RuleGroupType }[]
  preFillData: (index: number, groupBy: string, values: string[]) => void
}

type PropsPrefillExplorer = {
  axes: AxesResponses.Axis[]
  rules: {
    query: RuleGroupType
  }[]
  index: number
  preFillData: (groupBy: string, values: string[]) => void
}

const PrefillExplorerUI = ({
  axes,
  rules,
  index,
  preFillData,
}: PropsPrefillExplorer) => {
  // Getting groupBy dynamically based on the field selected in the rule using it's index.
  function getAxis() {
    const nbrQueryInRule = rules[index].query.rules.length
    if (index - 1 >= 0) {
      return _.get(
        rules,
        `[${index - 1}].query.rules[${nbrQueryInRule}].field`,
        axes[0].name,
      )
    }
    if (nbrQueryInRule > 0) {
      return getBestDrillDown(
        _.get(
          rules,
          `[${index}].query.rules[${nbrQueryInRule}].field`,
          axes[0].name,
        ),
      )
    }
    return getBestDrillDown(axes[0].name)
  }
  const getBestGroupBy = getAxis()
  const [groupBy, setGroupBy] = useState<string>(getBestGroupBy)
  const groupByOptions = useMemo(() => formatDatasources(axes), [axes])

  const query = useExplorerDrawer({
    previousRules: rules.filter((_, idx) => idx <= index),
    ruleIndex: index,
    groupBy,
  })
  return (
    <>
      <Stack>
        <Select
          w={300}
          label="Group By"
          placeholder="Select group by"
          allowDeselect={false}
          data={groupByOptions}
          searchable
          onChange={(val) => setGroupBy(val!)}
          value={groupBy}
          key="groupBy"
        />
        <QueryWrapper query={query}>
          {({ data }) => (
            <TableChartZoom
              preFillData={(values) => preFillData(groupBy, values)}
              data={data.groupedByValue}
            />
          )}
        </QueryWrapper>
      </Stack>
    </>
  )
}

export const PrefillExplorerDrawer = ({
  opened,
  close,
  rules,
  index,
  preFillData,
}: Props) => {
  const axesQuery = useQuery({
    queryKey: ["axes"],
    queryFn: async () => {
      const response = await apiClient.get<AxesResponses.Axis[]>("/axes")
      return response.data
    },
  })

  return (
    <>
      <Drawer
        size="xl"
        opened={opened}
        onClose={close}
        title="Explore Cost"
        position="right"
      >
        <QueryWrapper query={axesQuery}>
          {({ data: axes }) => (
            <PrefillExplorerUI
              preFillData={(groupBy, values) =>
                preFillData(index, groupBy, values)
              }
              axes={axes}
              rules={rules}
              index={index}
            />
          )}
        </QueryWrapper>
      </Drawer>
    </>
  )
}
