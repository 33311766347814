import { useEffect } from "react"

import { useAuthState } from "@costory/front/queries/auth"

export default function Gtm() {
  const auth = useAuthState()
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "page_view",
      page: window.location.href,
      env: import.meta.env.VITE_ENVIRONMENT,
      user: `${auth.user?.firstName} ${auth.user?.lastName}`,
      org: auth.user?.currentOrg.slug,
    })
  }, [auth])
  return null
}
