import { Group, Select } from "@mantine/core"
import { useNavigate } from "react-router-dom"

import { DashboardResponses } from "@costory/types/endpoints/dashboard"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { formatDashboardsList } from "@costory/front/utils/dashboards"

import { useDashboardListQuery } from "../../queries/dashboard"

type Props = {
  currentDashboard: DashboardResponses.DashboardT
}

export const DashboardSelector = ({ currentDashboard }: Props) => {
  const navigate = useNavigate()

  const dashboardListQuery = useDashboardListQuery()

  const handleSelectDashboard = (dashboardId: string | null) => {
    if (dashboardId) {
      navigate(`/home/dashboard/${dashboardId}`)
    }
  }

  return (
    <QueryWrapper query={dashboardListQuery} allowEmptyArray>
      {({ data: dashboards }) => (
        <Group>
          {dashboards.length > 0 && (
            <Select
              clearable
              searchable
              placeholder="Select a Dashboard"
              value={currentDashboard.id}
              onChange={handleSelectDashboard}
              data={formatDashboardsList(dashboards)}
            />
          )}
        </Group>
      )}
    </QueryWrapper>
  )
}
