import {
  ActionIcon,
  Button,
  Group,
  Input,
  Modal,
  SegmentedControl,
  Stack,
  Switch,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import {
  IconDeviceFloppy,
  IconPencil,
  IconPlus,
  IconX,
} from "@tabler/icons-react"
import { useState } from "react"

import { DashboardResponses } from "@costory/types/endpoints/dashboard"

import { DashboardType } from "@costory/front/components/dashboard/constants"
import { useAuthState } from "@costory/front/queries/auth"

import { DashboardSelector } from "./DashboardSelector"

type Props = {
  isEditing: boolean
  isCreatingDashboard: boolean
  isUpdatingDashboard: boolean
  onSave: (
    mode: string,
    name: string,
    type: DashboardType,
    defaultDashboard: boolean,
  ) => void
  onToggleEditing: (isEditing: boolean) => void
  onAddWidget: () => void
  currentDashboard: DashboardResponses.DashboardT
}
type SaveDashboardMode = "create" | "update"
export const DashboardActionBar = ({
  isEditing,
  isCreatingDashboard,
  isUpdatingDashboard,
  onSave,
  onToggleEditing,
  onAddWidget,
  currentDashboard,
}: Props) => {
  const [isOpen, { close, open }] = useDisclosure()
  const [saveMode, setSaveMode] = useState<SaveDashboardMode>("update")
  const [dashboardName, setDashboardName] = useState(currentDashboard.name)
  const [dashboardType, setDashboardType] = useState<DashboardType>(
    currentDashboard.type,
  )
  const [defaultDashboard, setDefaultDashboard] = useState<boolean>(
    currentDashboard.defaultDashboard,
  )

  const handleOpenSaveDashboardModal = (mode: SaveDashboardMode) => {
    setSaveMode(mode)
    if (currentDashboard) {
      if (mode === "update") {
        setDashboardName(currentDashboard.name)
      } else {
        setDashboardName(`${currentDashboard.name} - Copy`)
      }
    }
    open()
  }

  const handleSave = (saveMode: SaveDashboardMode) => {
    close()
    onSave(saveMode, dashboardName, dashboardType, defaultDashboard)
  }
  const user = useAuthState()
  return (
    <>
      <Group justify="flex-end">
        {isEditing ? (
          <>
            <Button
              variant="outline"
              leftSection={<IconPlus size={20} />}
              onClick={onAddWidget}
            >
              Add widget
            </Button>
            {currentDashboard.id &&
            currentDashboard.createdById === user.user?.id ? (
              <Button
                loading={isUpdatingDashboard}
                onClick={() => handleOpenSaveDashboardModal("update")}
                leftSection={<IconDeviceFloppy size={20} />}
              >
                Save
              </Button>
            ) : null}
            <Button
              loading={isCreatingDashboard}
              onClick={() => handleOpenSaveDashboardModal("create")}
              leftSection={<IconDeviceFloppy size={20} />}
            >
              Create New
            </Button>
            <ActionIcon
              variant="outline"
              size="lg"
              onClick={() => onToggleEditing(false)}
            >
              <IconX />
            </ActionIcon>
          </>
        ) : (
          <>
            <DashboardSelector currentDashboard={currentDashboard} />
            <Button
              leftSection={<IconPencil size={20} />}
              onClick={() => onToggleEditing(true)}
            >
              Edit Dashboard
            </Button>
          </>
        )}
      </Group>
      <Modal
        opened={isOpen}
        onClose={close}
        title={
          saveMode === "create"
            ? "Save a new dashboard"
            : `Update dashboard ${currentDashboard?.name}`
        }
      >
        <Stack>
          <Input
            flex={1}
            value={dashboardName}
            onChange={(e) => setDashboardName(e.currentTarget.value)}
          />
          <SegmentedControl
            value={dashboardType}
            onChange={(e) => setDashboardType(e as DashboardType)}
            data={[
              { label: "Public", value: "PUBLIC" },
              { label: "Private", value: "PRIVATE" },
            ]}
          />
          <Group>
            <Switch
              checked={defaultDashboard}
              size="xl"
              onChange={(event) =>
                setDefaultDashboard(event.currentTarget.checked)
              }
              onLabel="Default"
            />{" "}
            Set as Default
          </Group>
          <Button
            onClick={() => handleSave(saveMode)}
            loading={isCreatingDashboard || isUpdatingDashboard}
            disabled={!dashboardName}
          >
            Save
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
