import {
  ActionIcon,
  Button,
  Group,
  Input,
  Menu,
  Modal,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import {
  IconArrowsMaximize,
  IconDotsVertical,
  IconGripVertical,
  IconPencil,
  IconTrash,
} from "@tabler/icons-react"
import _ from "lodash"
import { useRef } from "react"

import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"

import { DATE_PRESET_OPTIONS } from "@costory/shared/utils/filters"

import { Chart } from "@costory/front/components/charts/Chart"
import { ChartType } from "@costory/front/components/dashboard/constants"

type Props = {
  title: string
  chartType: ChartType
  savedView: SavedViewsResponses.SavedView
  onDelete: () => void
  height: number
  isEditable: boolean
  navigate: (val: string) => void
  onRename: (val: string) => void
}

const CARD_HEADER_HEIGHT = 100 // px

export const DashboardWidget = ({
  title,
  chartType,
  navigate,
  savedView,
  onDelete,
  onRename,
  height,
  isEditable,
}: Props) => {
  const [isOpen, { open, close }] = useDisclosure()
  const inputRef = useRef<HTMLInputElement>(null)
  const handleSave = () => {
    if (inputRef.current) {
      onRename(inputRef.current.value)
      close()
    }
  }
  const timeHorizonLabel = _.filter(
    DATE_PRESET_OPTIONS,
    (t) => t.value === savedView.datePreset,
  )[0].label
  return (
    <Stack
      h="100%"
      p={16}
      align={chartType === "DONUT" ? "center" : undefined}
      style={{
        overflow: "visible",
      }}
    >
      <Group w="100%" justify="space-between">
        <Group gap={4} wrap="nowrap" flex={1}>
          {isEditable && (
            <ActionIcon size="sm" color="gray.3" className="dragHandle">
              <IconGripVertical />
            </ActionIcon>
          )}
          <Tooltip label={title} multiline={true} withinPortal={false} ml={7}>
            <Text fw="bold" lineClamp={2}>
              {title}
            </Text>
          </Tooltip>
          <Text lineClamp={2}>{timeHorizonLabel}</Text>
        </Group>
        {!isEditable && (
          <ActionIcon>
            <IconArrowsMaximize
              onClick={() =>
                navigate(
                  `/${chartType == "WATERFALL" ? "waterfall" : "explorer"}/views/${savedView.id}`,
                )
              }
            />
          </ActionIcon>
        )}
        {isEditable && (
          <Menu>
            <Menu.Target>
              <ActionIcon size="sm" color="gray.3">
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item leftSection={<IconPencil size={16} />} onClick={open}>
                Rename widget
              </Menu.Item>
              <Menu.Item
                leftSection={<IconTrash size={16} />}
                color="red"
                onClick={onDelete}
              >
                Delete widget
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Group>
      <Chart
        chartType={chartType}
        filters={savedView}
        height={height - CARD_HEADER_HEIGHT}
        withLegend={false}
      />
      <Modal
        opened={isOpen}
        onClose={close}
        title="Rename a widget"
        withinPortal
      >
        <Group>
          <Input
            flex={1}
            defaultValue={title}
            ref={inputRef}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSave()
              }
            }}
          />
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Modal>
    </Stack>
  )
}
