import { LineChart as BaseLineChart } from "@mantine/charts"

import { Filters } from "@costory/types/filters"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useExplorerQuery } from "@costory/front/queries/explore"
import { getCharteSerieColor } from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
}

export const LineChart = ({ filters, height, withLegend = true }: Props) => {
  const explorerQuery = useExplorerQuery(filters)
  return (
    <QueryWrapper query={explorerQuery}>
      {({ data }) => (
        <BaseLineChart
          h={height ?? "100%"}
          data={data.stackedBarChartData.arr}
          xAxisProps={{
            angle: 30,
            textAnchor: "start",
          }}
          dataKey="agg_date"
          withLegend={withLegend}
          curveType="linear"
          valueFormatter={(value) => formatNumber(value)}
          series={data.stackedBarChartData.items.map((item, index) => ({
            name: item.name,
            color: getCharteSerieColor(index),
          }))}
        />
      )}
    </QueryWrapper>
  )
}
