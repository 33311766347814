import { Filters } from "@costory/types/filters"

import { DonutChart } from "@costory/front/components/charts/DonutChart"
import { KPIChart } from "@costory/front/components/charts/KPIChart"
import { StackedBarChart } from "@costory/front/components/charts/StackedBarChart"
import { WaterfallChart } from "@costory/front/components/charts/WaterfallChart"
import { ChartType } from "@costory/front/components/dashboard/constants"

type Props = {
  chartType: ChartType
  filters: Filters
  height?: number
  withLegend?: boolean
}

export const Chart = ({
  chartType,
  filters,
  height,
  withLegend = true,
}: Props) => {
  switch (chartType) {
    case "TREND":
      return (
        <StackedBarChart
          filters={filters}
          height={height}
          withLegend={withLegend}
        />
      )
    case "DONUT":
      return <DonutChart filters={filters} height={height} />
    case "WATERFALL":
      return (
        <WaterfallChart
          filters={filters}
          height={height}
          withLegend={withLegend}
        />
      )
    case "KPI":
      return <KPIChart filters={filters} />
    case "KPI_BREAKDOWN":
      return <KPIChart filters={filters} withBreakdown />
  }
}
