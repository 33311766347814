import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

import { Modal, Paper, Stack, Text, ThemeIcon, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconLayoutOff } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { Layout, Responsive, WidthProvider } from "react-grid-layout"
import { useNavigate } from "react-router-dom"

import { DashboardResponses } from "@costory/types/endpoints/dashboard"
import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"

import {
  AddWidget,
  AddWidgetForm,
} from "@costory/front/components/dashboard/AddWidget"
import { DashboardActionBar } from "@costory/front/components/dashboard/DashboardActionBar"
import { DashboardWidget } from "@costory/front/components/dashboard/DashboardWidget"
import {
  CHART_TYPE_WIDGET_CONFIG,
  ChartType,
  DashboardType,
} from "@costory/front/components/dashboard/constants"
import {
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
} from "@costory/front/queries/dashboard"

const ResponsiveReactGridLayout = WidthProvider(Responsive)

type Widget = Layout & {
  title: string
  viewId: string
  chartType: ChartType
}

type Props = {
  currentDashboard: DashboardResponses.DashboardT
  savedViews: SavedViewsResponses.SavedView[]
}

export const DashboardLayout = ({ currentDashboard, savedViews }: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isOpen, { open, close }] = useDisclosure()
  const [hoveredWidget, setHoveredWidget] = useState<string | null>(null)

  const handleHover = (widgetId: string | null) => {
    setHoveredWidget(widgetId)
  }

  const [widgets, setWidgets] = useState<Widget[]>([])

  useEffect(() => {
    setWidgets(
      currentDashboard.dashboardWidgets!.map((widget, index) => ({
        i: `${index + 1}`,
        ...widget,
        ...CHART_TYPE_WIDGET_CONFIG[widget.chartType].config,
      })),
    )
  }, [currentDashboard])

  const { mutateAsync: saveDashboard, isPending: isUpdatingDashboard } =
    useUpdateDashboardMutation(() => setIsEditing(false))
  const { mutateAsync: createDashboard, isPending: isCreatingDashboard } =
    useCreateDashboardMutation(() => setIsEditing(false))

  const handleSave = async (
    saveMode: string,
    name: string,
    type: DashboardType,
    defaultDashboard: boolean,
  ) => {
    try {
      saveMode === "create"
        ? await createDashboard({
            name,
            type,
            defaultDashboard,
            dashboardWidgets: widgets,
          })
        : await saveDashboard({
            name,
            id: currentDashboard.id,
            type,
            defaultDashboard,
            dashboardWidgets: widgets,
          })
    } catch (e) {
      console.error(e)
    }
  }

  const handleToggleEditing = (editing: boolean) => {
    if (editing && widgets.length === 0) {
      open()
    }
    setIsEditing(editing)
  }

  const handleModify = (currentLayout: Layout[]) => {
    // setLayouts(allLayouts)
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) => {
        const matchingLayout = currentLayout.find(
          (layout) => layout.i === widget.i,
        )
        if (!matchingLayout) return widget
        return {
          ...widget,
          ...matchingLayout,
        }
      }),
    )
  }

  const handleAdd = (widgetConfig: AddWidgetForm) => {
    setWidgets((prevWidgets) => [
      ...prevWidgets,
      {
        i: `${widgetConfig.chartType}-${widgets.length + 1}`,
        x: 0,
        y: 0,
        ...widgetConfig,
        ...CHART_TYPE_WIDGET_CONFIG[widgetConfig.chartType].initial,
      },
    ])
    close()
  }

  const handleDelete = (key: string) => {
    setWidgets((prevWidgets) =>
      prevWidgets.filter((widget) => widget.i !== key),
    )
  }
  const handleEditTitle = (key: string, newTitle: string) => {
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) =>
        widget.i === key ? { ...widget, title: newTitle.trim() } : widget,
      ),
    )
  }
  const navigate = useNavigate()

  return (
    <Stack>
      <DashboardActionBar
        isEditing={isEditing}
        isCreatingDashboard={isCreatingDashboard}
        isUpdatingDashboard={isUpdatingDashboard}
        onSave={handleSave}
        onToggleEditing={handleToggleEditing}
        onAddWidget={open}
        currentDashboard={currentDashboard}
      />
      {widgets.length ? (
        <ResponsiveReactGridLayout
          onDragStop={handleModify}
          onResizeStop={handleModify}
          preventCollision={false}
          verticalCompact
          margin={[20, 20]}
          containerPadding={[0, 0]}
          draggableHandle=".dragHandle"
          resizeHandles={isEditing ? ["sw", "nw", "se", "ne"] : []}
          cols={{ lg: 8, md: 6, sm: 4, xs: 2, xxs: 2 }}
          autoSize
        >
          {widgets.map((widget) => {
            const view = savedViews.find((view) => view.id === widget.viewId)
            if (!view) return null
            return (
              <Paper
                p={0}
                key={widget.i}
                onMouseOver={() => handleHover(widget.i)}
                onMouseOut={() => handleHover(null)}
                style={{
                  zIndex:
                    hoveredWidget === widget.i
                      ? "100"
                      : widgets.length - widgets.indexOf(widget),
                }}
                data-grid={{
                  x: widget.x,
                  y: widget.y,
                  w: widget.w,
                  h: widget.h,
                  i: widget.i,
                  ...CHART_TYPE_WIDGET_CONFIG[widget.chartType].config,
                  isResizable:
                    isEditing &&
                    CHART_TYPE_WIDGET_CONFIG[widget.chartType].isResizable,
                  isDraggable: isEditing,
                }}
              >
                <DashboardWidget
                  navigate={navigate}
                  title={widget.title}
                  height={widget.h * 160}
                  savedView={view}
                  chartType={widget.chartType}
                  onDelete={() => handleDelete(widget.i)}
                  onRename={(title) => handleEditTitle(widget.i, title)}
                  isEditable={isEditing}
                />
              </Paper>
            )
          })}
        </ResponsiveReactGridLayout>
      ) : (
        <Stack align="center" py={40}>
          <ThemeIcon size={70}>
            <IconLayoutOff size={70} />
          </ThemeIcon>
          <Title>
            Your dashboard is empty or you havent set a default one{" "}
          </Title>
          <Text fz="xl">
            Start exploring your data, create views and add widgets!
          </Text>
        </Stack>
      )}
      <Modal
        opened={isOpen}
        onClose={close}
        title="Add a new widget"
        size={1000}
      >
        <AddWidget onAdd={handleAdd} />
      </Modal>
    </Stack>
  )
}
