import { Group, Stack } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { showNotification } from "@mantine/notifications"
import _ from "lodash"
import { JSX, useCallback, useEffect, useMemo, useState } from "react"
import { formatQuery, add } from "react-querybuilder"
import { v4 as uuidv4 } from "uuid"

import { AxesResponses } from "@costory/types/endpoints/axes"
import { MetricsResponses } from "@costory/types/endpoints/metrics"
import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"
import { filterBarSchema, Filters } from "@costory/types/filters"

import { ALL_OTHERS, UNLABELLED } from "@costory/shared/utils/explorer_data"
import { computePresetDates } from "@costory/shared/utils/filters"

import { FilterBar } from "@costory/front/components/FilterBar"
import { SavedViewsSelector } from "@costory/front/components/SavedViewsSelector"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useAxesQuery } from "@costory/front/queries/axes"
import { useBusinessMetricsQuery } from "@costory/front/queries/businessMetrics"
import { SavedViewRedirectPage } from "@costory/front/queries/savedViews"
import {
  DEFAULT_QUERY_BUILDER_FILTER,
  formatDatasources,
  useFiltersSearchParamsBinding,
} from "@costory/front/utils/filters"

type RootProps = {
  redirectPage: SavedViewRedirectPage
  savedView?: SavedViewsResponses.SavedView
  children: (props: {
    filters: Filters
    drillDownInto: (groupBy: string, value: string, newGroupBy: string) => void
  }) => JSX.Element | null
}

// The `key` is forced here because it's used to properly rerender form when saving a view
type QueryContainerProps = RootProps & { key: string }

type Props = RootProps & {
  axes: AxesResponses.Axis[]
  metrics: MetricsResponses.Metric[]
}

const _ChartContainer = ({
  axes,
  metrics,
  savedView,
  redirectPage,
  children,
}: Props) => {
  const metricsOptions = useMemo(
    () =>
      _.sortBy(metrics, (d) => Number(!("technical" in d))).map((metric) => ({
        label: metric.name,
        value: metric.id,
      })),
    [metrics],
  )
  const groupByOptions = useMemo(() => formatDatasources(axes), [axes])

  const filtersValidator = useMemo(
    () => filterBarSchema(axes.map((axis) => axis.name)),
    [axes],
  )

  const validateFilters = useCallback(
    (filters: Filters) => {
      const validatedFilters = filtersValidator.safeParse(filters)
      if (validatedFilters.success) {
        const transformedFilters = {
          ...validatedFilters.data,
          whereClause: JSON.parse(
            formatQuery(validatedFilters.data.whereClause, "json_without_ids"),
          ),
        }
        return {
          ...validatedFilters,
          data: transformedFilters as Filters,
        }
      }
      return validatedFilters
    },
    [filtersValidator],
  )

  const { urlFilters, setURLFilters } =
    useFiltersSearchParamsBinding(validateFilters)

  const initialValues = useMemo<Filters>(() => {
    if (urlFilters) return urlFilters
    if (savedView) {
      const validatedSavedView = validateFilters(savedView)
      if (validatedSavedView.success) return validatedSavedView.data
      showNotification({
        title: "Invalid saved view",
        message:
          "This saved view configuration is invalid. It might be outdated.",
        color: "red",
      })
    }
    return {
      limit: 3,
      aggBy: "Day",
      metricId: metricsOptions[0].value,
      groupBy: groupByOptions[0].items[0].value,
      ...computePresetDates("LAST_MONTH"),
      whereClause: DEFAULT_QUERY_BUILDER_FILTER,
    }
  }, [urlFilters, savedView, metricsOptions, groupByOptions, validateFilters])
  const handleFilterChange = useCallback(
    (current: Filters, previous: Filters) => {
      const validatedFilters = validateFilters(current)
      if (validatedFilters.success) {
        console.log("form change", current, previous)
        setFilters(validatedFilters.data)
        if (!_.isEqual(validatedFilters.data, initialValues)) {
          setURLFilters(validatedFilters.data)
        }
      }
    },
    [initialValues, setURLFilters, validateFilters],
  )

  const filterForm = useForm<Filters>({
    initialValues,
    validate: zodResolver(filtersValidator),
    mode: "uncontrolled",
    validateInputOnBlur: true,
    onValuesChange: handleFilterChange,
  })

  const [filters, setFilters] = useState<typeof filterForm.values>(
    filterForm.values,
  )

  // Change filters on URL change (when hitting back)
  useEffect(() => {
    if (urlFilters && !_.isEqual(urlFilters, filters)) {
      filterForm.setValues(urlFilters)
    }
  }, [filterForm, filters, urlFilters])

  // // Reset form when filters are removed from URL (when hitting back)
  useEffect(() => {
    if (!urlFilters && !_.isEqual(initialValues, filters)) {
      filterForm.reset()
    }
  }, [filterForm, filters, initialValues, urlFilters])

  const drillDownInto = (
    groupBy: string,
    value: string | undefined,
    newGroupBy: string,
  ) => {
    if (value === ALL_OTHERS || value === UNLABELLED) {
      return
    }
    if (value === "Current" || value === "Previous" || !value) {
      return
    }
    const toSet = { groupBy: newGroupBy }
    if (value == UNLABELLED) {
      filterForm.setValues({
        whereClause: add(
          filters.whereClause,
          {
            id: uuidv4(),
            field: groupBy,
            operator: "null",
            value: [],
          },
          [],
        ),
        ...toSet,
      })
    } else {
      filterForm.setValues({
        whereClause: add(
          filters.whereClause,
          {
            id: uuidv4(),
            field: groupBy,
            operator: "in",
            value: [value],
          },
          [],
        ),
        ...toSet,
      })
    }
  }

  return (
    <Stack gap={32}>
      <Group justify="flex-end">
        <SavedViewsSelector
          currentView={savedView}
          redirectPage={redirectPage}
          form={filterForm}
        />
      </Group>
      <FilterBar
        form={filterForm}
        metricsOptions={metricsOptions}
        groupByOptions={groupByOptions}
      />
      {children({ filters, drillDownInto })}
    </Stack>
  )
}

export const ChartContainer = ({
  savedView,
  redirectPage,
  children,
}: QueryContainerProps) => {
  const axesQuery = useAxesQuery()
  const metricsQuery = useBusinessMetricsQuery()

  return (
    <QueryWrapper query={axesQuery}>
      {({ data: axes }) => (
        <QueryWrapper query={metricsQuery}>
          {({ data: metrics }) => (
            <_ChartContainer
              axes={axes}
              metrics={metrics}
              savedView={savedView}
              redirectPage={redirectPage}
            >
              {(propsWithFilters) => children(propsWithFilters)}
            </_ChartContainer>
          )}
        </QueryWrapper>
      )}
    </QueryWrapper>
  )
}
