export type NumberType = "decimal" | "currency" | "percent"

export const formatNumber = (
  value: number,
  numberType: NumberType = "currency",
  maxDigit: number = 2,
) => {
  if (numberType === "percent" && (Math.abs(value) > 9.9 || value == null)) {
    return "\u221e" //Infinite Character
  }
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDigit,
    notation: "compact",
    style: numberType,
    signDisplay: numberType === "percent" ? "exceptZero" : "negative",
  }
  if (numberType === "currency") {
    options.currency = "USD"
    options.currencyDisplay = "narrowSymbol"
    options.maximumFractionDigits = maxDigit
    options.minimumFractionDigits = 0
  }
  const formatter = new Intl.NumberFormat("en-US", options)

  return formatter.format(value)
}
