import { Button, Group, Stack, Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { IconPencil, IconTrash } from "@tabler/icons-react"
import _ from "lodash"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useAuthState } from "@costory/front/queries/auth"
import {
  useDeleteSavedView,
  useListSavedViewsQuery,
} from "@costory/front/queries/savedViews"
import { getDefaultTableOptions } from "@costory/front/utils/table"

type Props = {
  savedViews: SavedViewsResponses.SavedView[]
}

const SavedViews = ({ savedViews }: Props) => {
  const navigate = useNavigate()
  const { mutateAsync: deleteSavedView, isPending: isPendingDeletion } =
    useDeleteSavedView()
  const auth = useAuthState()
  const openModal = useCallback(
    (id: string, name: string, user: string) =>
      modals.openConfirmModal({
        title: "Please confirm your action",
        children: (
          <Text size="sm">
            This will delete view
            <Text span px={4} fw={700}>
              {name}
            </Text>
            created by
            <Text span pl={4} fw={700}>
              {user}
            </Text>
            . Are you sure?
          </Text>
        ),
        labels: { confirm: "Confirm", cancel: "Cancel" },
        onConfirm: () => deleteSavedView(id),
      }),
    [deleteSavedView],
  )

  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<SavedViewsResponses.SavedViewsWithCreatorFullName>()
    return [
      columnHelper.accessor("name", {
        header: "Name",
        Cell: ({ cell }) => (
          <Text
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/explorer/views/${cell.row.original.id}`)}
          >
            {cell.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor((row) => _.capitalize(row.type), {
        header: "Type",
      }),
      columnHelper.accessor("metricId", {
        header: "Metric Id",
      }),
      columnHelper.accessor(
        (row) => (row.datePreset ? row.datePreset : "CUSTOM"),
        {
          header: "Data Preset",
        },
      ),
      columnHelper.accessor("groupBy", {
        header: "Group By",
      }),
      columnHelper.accessor((row) => `${row.createdBy.fullName}`, {
        header: "Owner",
      }),
      columnHelper.accessor("aggBy", {
        header: "Agg By",
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Group gap={4} wrap="nowrap">
            <Button
              size="xs"
              variant="outline"
              disabled={row.original.createdBy.id !== auth.user?.id}
              leftSection={<IconPencil size={16} />}
              onClick={() => navigate(`/explorer/views/${row.original.id}`)}
            >
              Edit
            </Button>
            <Button
              size="xs"
              variant="outline"
              color="red"
              disabled={
                !auth.user?.isAdmin &&
                row.original.createdBy.id !== auth.user?.id
              }
              loading={isPendingDeletion}
              onClick={() =>
                openModal(
                  row.original.id,
                  row.original.name,
                  row.original.createdBy.firstName,
                )
              }
              leftSection={<IconTrash size={16} />}
            >
              Delete
            </Button>
          </Group>
        ),
      }),
    ]
  }, [auth.user, isPendingDeletion, navigate, openModal])

  const table =
    useMantineReactTable<SavedViewsResponses.SavedViewsWithCreatorFullName>({
      columns,
      data: savedViews as SavedViewsResponses.SavedViewsWithCreatorFullName[],
      ...getDefaultTableOptions(),
    })

  return (
    <Stack>
      <MantineReactTable table={table} />
    </Stack>
  )
}

export const SavedViewsPage = () => {
  const savedViewsData = useListSavedViewsQuery()

  return (
    <QueryWrapper query={savedViewsData}>
      {({ data }) => <SavedViews savedViews={data} />}
    </QueryWrapper>
  )
}
