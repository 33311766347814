import { Button, Group, Stack, Text } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"

import {
  JobExecutionsRequests,
  JobExecutionsResponses,
  listJobsZod,
} from "@costory/types/endpoints/jobExecutions"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"
import { getDefaultTableOptions } from "@costory/front/utils/table"

type Props = {
  insights: JobExecutionsResponses.ListJobs[]
}

const JobExecutions = ({ insights }: Props) => {
  const { mutateAsync: createUpdateJob, isPending: isPendingCreation } =
    useMutation({
      mutationFn: async () => {
        const response = await apiClient.post<JobExecutionsRequests.StartJob>(
          "/job-executions/start-job/update/",
        )
        return response.data
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ["jobExecutions"] })
        showNotification({
          color: "green",
          title: "Job Started",
          message: "The job is running",
        })
      },
      onError: (error: { error: string }) => {
        showNotification({
          color: "red",
          title: "Error",
          message: error.error,
        })
      },
    })
  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<JobExecutionsResponses.ListJobs>()
    return [
      columnHelper.accessor("startedAt", {
        header: "Started At",
        Cell: ({ cell }) => (
          <span>
            {cell.row.original.startedAt
              ? cell.row.original.startedAt.toLocaleString()
              : ""}
          </span>
        ),
      }),
      columnHelper.accessor("endedAt", {
        header: "Ended At",
        Cell: ({ cell }) => <span>{cell.getValue()?.toLocaleString()}</span>,
      }),
      columnHelper.accessor("state", {
        header: "Status",
        filterVariant: "select",
      }),
    ]
  }, [])

  const table = useMantineReactTable<JobExecutionsResponses.ListJobs>({
    columns,
    data: insights,
    ...getDefaultTableOptions(),
  })

  return (
    <Stack>
      <Group justify="space-between">
        <Text>List last update Virtual dimensions Jobs</Text>
        <Group>
          <Button
            loading={isPendingCreation}
            onClick={() =>
              queryClient.invalidateQueries({ queryKey: ["jobExecutions"] })
            }
          >
            Refresh
          </Button>
          <Button loading={isPendingCreation} onClick={() => createUpdateJob()}>
            Create Update Job
          </Button>
        </Group>
      </Group>

      <MantineReactTable table={table} />
    </Stack>
  )
}

export const JobExecutionsPage = () => {
  const insightsQuery = useQuery({
    queryKey: ["jobExecutions"],
    queryFn: async () => {
      const response =
        await apiClient.get<JobExecutionsResponses.ListJobs[]>(
          "/job-executions",
        )
      return listJobsZod.safeParse(response.data).data
    },
  })

  return (
    <QueryWrapper query={insightsQuery} allowEmptyArray>
      {({ data }) => <JobExecutions insights={data || []} />}
    </QueryWrapper>
  )
}
