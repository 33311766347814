import {
  Divider,
  Flex,
  NumberFormatter,
  Paper,
  SimpleGrid,
  Text,
} from "@mantine/core"
import _, { find } from "lodash"

import { NumberCell } from "@costory/front/components/table/NumberCell"
import { PayloadItem } from "@costory/front/utils/charts"

function WaterfallChartTooltip({
  label,
  payload,
  tooltipData,
}: {
  label: string
  payload?: PayloadItem[]
  tooltipData: {
    groupByValue: string
    periodCost: number
    previousPeriodCost: number
    change: number
    relativeChange: number
  }[]
}) {
  if (!payload || payload.length === 0) return null

  const waterfallData = find(tooltipData, { groupByValue: label })

  const displayLabel = label || payload[0].name

  const isCurrentOrPrevious = ["Current", "Previous"].includes(label)

  const totalchange = _.sumBy(tooltipData, "change")
  const previous = _.sumBy(tooltipData, "previousPeriodCost")
  return (
    <Paper
      px="md"
      py="sm"
      withBorder
      shadow="md"
      radius="md"
      style={{
        width: "full",
        position: "relative",
        zIndex: 1000,
      }}
    >
      <Flex mb="sm" justify="space-between" align="center">
        <Text mr="15" fw={700}>
          {displayLabel}
        </Text>
      </Flex>
      <Divider />
      {payload.map((item) => (
        <SimpleGrid cols={1} key={item.name} spacing="xl" verticalSpacing="xs">
          <Flex mt={14} justify="space-between" align="center">
            <Text mr="15" fw={700}>
              Cost
            </Text>
            <Text size="sm">
              <NumberFormatter
                prefix="$ "
                thousandSeparator
                value={
                  !isCurrentOrPrevious
                    ? waterfallData?.periodCost.toFixed(2)
                    : label !== "Current"
                      ? item.payload.cost[1].toFixed(2)
                      : item.payload.cost[0].toFixed(2)
                }
              />
            </Text>
          </Flex>

          {label === "Current" && (
            <>
              <Flex justify="space-between" align="center">
                <Text fw={700} mr="md">
                  Total Change
                </Text>
                <NumberCell
                  value={Number(totalchange.toFixed(2))}
                  numberType="currency"
                />
              </Flex>
              <Flex justify="space-between" align="center">
                <Text fw={700} mr="md">
                  Total % Change
                </Text>
                <NumberCell
                  value={Number(totalchange / previous)}
                  numberType="percent"
                />
              </Flex>
            </>
          )}
          {!isCurrentOrPrevious && (
            <>
              <Flex justify="space-between" align="center">
                <Text mr="15" fw={700}>
                  Previous Cost
                </Text>
                <Text size="sm">
                  <NumberFormatter
                    prefix="$ "
                    thousandSeparator
                    value={waterfallData?.previousPeriodCost.toFixed(2)}
                  />
                </Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text fw={700}>Absolute Diff</Text>
                <NumberCell value={waterfallData?.change as number} />
              </Flex>
              <Flex justify="space-between" align="center">
                <Text mr="15" fw={700}>
                  Relative Diff
                </Text>
                <NumberCell
                  value={waterfallData?.relativeChange as number}
                  numberType="percent"
                />
              </Flex>
            </>
          )}
        </SimpleGrid>
      ))}
    </Paper>
  )
}

export default WaterfallChartTooltip
