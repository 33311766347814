import { ChartTooltipProps } from "@mantine/charts"
import { Badge, Box, Divider, Flex, Group, Paper, Text } from "@mantine/core"
import _ from "lodash"
import { useMemo } from "react"

import { formatNumber } from "@costory/front/utils/format"

interface ExtendedChartTooltipProps extends ChartTooltipProps {
  tooltipData?: {
    groupByValue: string
    periodCost: number
    previousPeriodCost: number
    change: number
    relativeChange: number
  }[]
}

export const ChartTooltip = ({
  label,
  payload,
  tooltipData,
}: ExtendedChartTooltipProps) => {
  const displayLabel = useMemo(
    () => label ?? payload?.[0]?.name ?? "",
    [label, payload],
  )
  const totalValue = useMemo(() => _.sumBy(payload, (p) => p.value), [payload])

  const tooltip = useMemo(
    () =>
      _.find(tooltipData, {
        groupByValue: displayLabel,
      }),
    [displayLabel, tooltipData],
  )

  if (!payload || payload.length === 0) return null
  return (
    <Paper
      withBorder
      pos="relative"
      w={350}
      style={{
        transform: tooltip ? "translateX(-5vw)" : "none",
        zIndex: 1000,
      }}
    >
      <Flex mb="sm" justify="space-between" align="center">
        <Text
          fw={700}
          fz="md"
          maw={350}
          mr="md"
          style={{
            ...(tooltip
              ? {
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                }
              : {}),
          }}
        >
          {displayLabel}
        </Text>
        {tooltip ? (
          <Box>
            <Badge
              size="lg"
              color={
                tooltip?.relativeChange && tooltip.relativeChange > 0
                  ? "red.5"
                  : "green.5"
              }
            >
              {formatNumber(tooltip.relativeChange, "percent", 0)}
            </Badge>
          </Box>
        ) : (
          <Text fz="md">{formatNumber(totalValue)}</Text>
        )}
      </Flex>
      <Divider />
      {payload.map(({ name, value, color, payload }) => (
        <Group key={name} gap={1} mt={8} justify="space-between" wrap="nowrap">
          <Group gap={8} wrap="nowrap" mr={30}>
            <Box
              w={8}
              h={8}
              style={{ borderRadius: 4 }}
              bg={color || payload.fill}
            />
            <Text
              fw={700}
              mr="sm"
              style={{
                whiteSpace: "normal",
                overflowWrap: "break-word",
                wordBreak: "break-word",
              }}
            >
              {name}
            </Text>
          </Group>
          <Text fz="md">{formatNumber(value)}</Text>
        </Group>
      ))}
    </Paper>
  )
}
