import { PieChart } from "@mantine/charts"
import { Button, Group, Modal, NumberInput, Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import _ from "lodash"
import { useState } from "react"

import { VirtualDimensionsRequests } from "@costory/types/endpoints/virtualDimensions"

import { SelectCreatable } from "@costory/front/components/SelectCreatable"
import SplitCostResult from "@costory/front/pages/VirtualDimensions/components/SplitCostResult"
import { getCharteSerieColor } from "@costory/front/utils/charts"

type Props = {
  form: UseFormReturnType<VirtualDimensionsRequests.EditVirtualDimension>
  costRule: number | undefined
  prefixForm: string
  allocation: VirtualDimensionsRequests.EditVirtualDimension["rules"][0]["allocation"]
}
export const CustomReallocation = ({
  form,
  costRule,
  prefixForm,
  allocation,
}: Props) => {
  const [opened, { open, close }] = useDisclosure(false)
  const [tempData, setTempData] = useState<{
    column: string | undefined
    weight: number | undefined
    previousColumn: string | undefined
  }>({ column: "", weight: 0, previousColumn: "unAllocated" })
  if (allocation.allocationType != "splitCost") {
    return <></>
  }
  if (allocation.reAllocationParams.type != "custom") {
    return <></>
  }
  function handleClickEdit(pieName: string) {
    if (pieName == "unAllocated") {
      return
    }
    setTempData({
      column: pieName,
      weight: tobeAllocated,
      previousColumn: pieName,
    })
    open()
  }
  function computeTotal(filterByName: string | undefined = undefined) {
    if (filterByName) {
      return partitions
        .filter((elem) => elem.label != filterByName)
        .reduce((previous, current) => previous + current.weight, 0)
    }
    return partitions.reduce(
      (previous, current) => previous + current.weight,
      0,
    )
  }
  const partitions = allocation.reAllocationParams.partitions
  const totalAllocated = computeTotal()
  const tobeAllocated = 100 - totalAllocated
  const partitionsWithUnalocated = [
    ...partitions,
    ...(tobeAllocated > 0
      ? [{ label: "unAllocated", weight: tobeAllocated }]
      : []),
  ]
  function addLabelToAttribution(
    newWeight: number,
    newLabel: string,
    previousLabel: string | undefined,
  ) {
    if (previousLabel) {
      // edit case
      form.setFieldValue(
        `${prefixForm}.reAllocationParams.partitions.${_.findIndex(partitions, (d) => d.label == previousLabel)}`,
        {
          label: newLabel,
          weight: newWeight,
        },
      )
    } else {
      const idx = _.findIndex(partitions, (d) => d.label == newLabel)
      if (idx === -1) {
        form.setFieldValue(`${prefixForm}.reAllocationParams.partitions`, [
          ...partitions,
          { weight: newWeight, label: newLabel },
        ])
      } else {
        //merge case
        form.setFieldValue(
          `${prefixForm}.reAllocationParams.partitions.${idx}`,
          {
            label: newLabel,
            weight: newWeight,
          },
        )
      }
    }
  }
  return (
    <>
      <Stack>
        <Group justify="flex-start" grow>
          <Text>
            Using the pie chart and add button, reallocate the cost so that none
            is unallocated.
          </Text>
          <Group justify="center">
            <Button
              onClick={() => {
                setTempData({
                  column: undefined,
                  weight: tobeAllocated,
                  previousColumn: undefined,
                })
                open()
              }}
            >
              Add
            </Button>
          </Group>
        </Group>
        <PieChart
          data={partitionsWithUnalocated.map((elem, index) => ({
            name: elem.label,
            value: elem.weight,
            color: getCharteSerieColor(index),
          }))}
          pieProps={{
            dataKey: "value",
            onClick: (d) => handleClickEdit(d.name),
          }}
          withTooltip
          labelsPosition="inside"
          labelsType="percent"
          withLabels
          mx="auto"
        />

        <SplitCostResult costRule={costRule} allocation={allocation} />
      </Stack>

      <Modal
        opened={opened}
        onClose={close}
        title="Choose Reattribution key"
        closeOnClickOutside={false}
      >
        <Stack>
          <SelectCreatable
            data={form.getValues().values}
            onChange={(data) =>
              setTempData({
                column: data!,
                weight: tempData?.weight,
                previousColumn: tempData.previousColumn,
              })
            }
            error={tempData.column?.length == 0}
            handleCreate={(val) => form.insertListItem("values", val)}
          />
          <NumberInput
            value={tempData?.weight}
            max={
              tempData.previousColumn
                ? 100 - computeTotal(tempData.previousColumn)
                : 100 - computeTotal()
            }
            min={0}
            onValueChange={(val) =>
              setTempData({
                column: tempData?.column,
                weight: val.floatValue,
                previousColumn: tempData.previousColumn,
              })
            }
          />
          <Button
            onClick={() => {
              addLabelToAttribution(
                tempData.weight!,
                tempData.column!,
                tempData.previousColumn,
              )
              close()
            }}
            disabled={
              (tempData.weight ?? 0) <= 0 || !tempData.column
                ? true
                : tempData.previousColumn
                  ? computeTotal(tempData.previousColumn) +
                      (tempData.weight ?? 0) >
                    100
                  : computeTotal() + (tempData.weight ?? 0) > 100
            }
          >
            Add / Edit
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
