import { Protect } from "@clerk/clerk-react"
import {
  Box,
  Collapse,
  Group,
  rem,
  ThemeIcon,
  UnstyledButton,
  Flex,
  Tooltip,
} from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { IconChevronRight } from "@tabler/icons-react"
import { ComponentType, useState, FC } from "react"
import { NavLink } from "react-router-dom"

import { colors } from "@costory/front/assets/theme/colors"
import { SidebarItem } from "@costory/front/components/layout/constants"

type Props = {
  route: SidebarItem
  isCollapsed: boolean
}

const HoverableLink: FC<{
  icon: ComponentType
  name: string
  path: string
}> = ({ icon: Icon, name, path }) => {
  const { ref, hovered } = useHover()

  return (
    <Box pl="lg" my="xs" ref={ref}>
      <NavLink
        to={path}
        key={name}
        style={({ isActive }) => ({
          color: hovered || isActive ? "white" : colors.gray[3],
        })}
      >
        <Flex>
          <ThemeIcon color="white.4" size="sm">
            {Icon && <Icon />}
          </ThemeIcon>
          <Box ml="xs" ta="left">
            {name}
          </Box>
        </Flex>
      </NavLink>
    </Box>
  )
}

export const SidebarLink: FC<Props> = ({ route, isCollapsed }) => {
  const [opened, setOpened] = useState(false)
  const { ref: groupRef, hovered: groupHovered } = useHover<HTMLButtonElement>()
  const hasLinks = !!route.links?.length

  return (
    <Protect permission={route.role}>
      <UnstyledButton
        onClick={() => setOpened(!opened)}
        ref={groupRef}
        mx="0.3rem"
        my="0.5rem"
      >
        <NavLink
          to={route.path || "#"}
          key={route.name}
          style={({ isActive }) => ({
            color: groupHovered || isActive ? "white" : colors.gray[3],
            background:
              groupHovered || isActive ? colors.gray[6] : "transparent",
          })}
        >
          <Tooltip
            label={route.name}
            position="right"
            offset={24}
            color="gray.8"
            display={isCollapsed ? "flex" : "none"}
          >
            <Group justify="space-between" gap={0}>
              <Flex>
                <ThemeIcon c="inherit" ml="0.3rem" size="sm">
                  {route.icon && <route.icon />}
                </ThemeIcon>
                {!isCollapsed && (
                  <>
                    <Box pl="0.3rem" ta="left" size="sm">
                      {route.name}
                    </Box>
                    {hasLinks && (
                      <IconChevronRight
                        color="white"
                        stroke={1.5}
                        style={{
                          margin: rem(4),
                          width: rem(16),
                          height: rem(16),
                          transform: opened ? "rotate(90deg)" : "none",
                          transition: "transform 200ms ease",
                        }}
                      />
                    )}
                  </>
                )}
              </Flex>
            </Group>
          </Tooltip>
        </NavLink>
      </UnstyledButton>
      {!isCollapsed && hasLinks && (
        <Collapse in={opened}>
          {route.links?.map((link) => (
            <HoverableLink
              icon={link.icon as ComponentType}
              name={link.name}
              path={link.path as string}
              key={link.name}
            />
          ))}
        </Collapse>
      )}
    </Protect>
  )
}
