import {
  Combobox,
  getParsedComboboxData,
  InputBase,
  isOptionsGroup,
  useCombobox,
  Button,
  ComboboxData,
} from "@mantine/core"
import { useEffect, useMemo, useState } from "react"

import { useFilteredOptions } from "@costory/front/utils/selectComponents"

type Props = {
  data: ComboboxData
  value?: string
  onChange: (value: string) => void
  handleCreate: (value: string) => void
  error: React.ReactNode
  defaultValue?: string
}

export const SelectCreatable = (props: Props) => {
  const { value } = props

  const setValue = props.onChange
  const data = useMemo(() => {
    return getParsedComboboxData(props.data) ?? []
  }, [props.data])
  const [search, setSearch] = useState(value || "")
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
    },
  })
  useEffect(() => {}, [props.data])

  const hasExactOptionMatch = useMemo(() => {
    return data.some((option) => {
      if (isOptionsGroup(option)) {
        return option.items.some((item) => item.value === search)
      }
      return option.value === search
    })
  }, [data, search])

  const handleCreate = () => {
    setValue(search)
    setSearch(search)
    props.handleCreate(search)
  }

  const filteredOptions = useFilteredOptions(data, search)

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setValue(val)
        setSearch(val)
        props.onChange(val)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <InputBase
          error={props.error}
          rightSection={<Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
            setSearch(event.currentTarget.value)
          }}
          onClick={() => {
            setSearch("")
            combobox.openDropdown()
          }}
          onKeyDown={(e) => {
            const value = e.currentTarget.value
            if (e.key === "Enter" && value.length) {
              setSearch(value)
              setValue(value)
              props.data.includes(value)
                ? combobox.updateSelectedOptionIndex()
                : props.handleCreate(value)
            }
          }}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown()
            setSearch(value || "")
          }}
          placeholder="Search value"
          rightSectionPointerEvents="none"
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {filteredOptions.map((option) => {
            if (isOptionsGroup(option)) {
              return (
                <Combobox.Group key={option.group} label={option.group}>
                  {option.items.map((item) => (
                    <Combobox.Option value={item.value} key={item.label}>
                      {item.value}
                    </Combobox.Option>
                  ))}
                </Combobox.Group>
              )
            }
            return (
              <Combobox.Option value={option.value} key={option.label}>
                {option.value}
              </Combobox.Option>
            )
          })}
          {!hasExactOptionMatch && search.trim().length > 0 && (
            <Combobox.Header onClick={handleCreate} p={0}>
              <Button
                variant="subtle"
                size="sm"
                color="gray"
                w="calc(100% - 4px)"
                radius="xs"
                style={{
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                + Create “{search}“
              </Button>
            </Combobox.Header>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
