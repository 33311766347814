import { Group, Select, ComboboxData, Stack, Tooltip } from "@mantine/core"
import { DatePickerInput, DatesRangeValue } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import _ from "lodash"
import { useCallback, useState } from "react"

import { AGG_BY_OPTIONS, DatePreset, Filters } from "@costory/types/filters"

import { getDayJs } from "@costory/shared/utils/dates"
import {
  computeComparisonPeriodDates,
  computePresetDates,
  DATE_PRESET_OPTIONS,
  LIMIT_OPTIONS,
} from "@costory/shared/utils/filters"

import { QueryBuilder } from "@costory/front/components/QueryBuilder"

type Props = {
  form: UseFormReturnType<Filters>
  metricsOptions: ComboboxData
  groupByOptions: ComboboxData
}

dayjs.extend(utc)

export const FilterBar = ({ form, metricsOptions, groupByOptions }: Props) => {
  const [dateRange, setDateRange] = useState<DatesRangeValue>([
    form.getValues().from,
    form.getValues().to,
  ])

  const handleSelectDateRange = useCallback(
    (dateRange: DatesRangeValue) => {
      const [fromDate, toDate] = dateRange
      setDateRange([fromDate, toDate])

      // So date values match the ones from z.coerce.date()
      const from = fromDate ? getDayJs()(fromDate).toDate() : null
      const to = toDate ? getDayJs()(toDate).toDate() : null

      if (from && to) {
        form.setValues({
          from,
          to,
          datePreset: null,
          ...computeComparisonPeriodDates(from, to, null),
        })
      }
    },
    [form],
  )

  const handleSelectDatePreset = useCallback(
    (selectedPreset: string | null) => {
      if (!selectedPreset) return
      const presetDates = computePresetDates(selectedPreset as DatePreset)
      setDateRange([presetDates.from, presetDates.to])
      form.setValues(presetDates)
    },
    [form],
  )

  const { previousFrom, previousTo, datePreset } = form.getValues()
  const datePickerTooltip = `Comparing to ${datePreset ? _.startCase(datePreset) : "PREVIOUS PERIOD"}: ${dayjs(previousFrom).format("MMM DD, YYYY")} - ${dayjs(previousTo).format("MMM DD, YYYY")}`

  return (
    <Stack>
      <Group align="flex-end">
        <Select
          w={70}
          label="Limit"
          placeholder="Select limit"
          allowDeselect={false}
          data={LIMIT_OPTIONS}
          {...form.getInputProps("limit")}
          key={form.key("limit")}
          value={form.getValues().limit.toString()}
          onChange={(value) =>
            value ? form.setFieldValue("limit", parseInt(value)) : null
          }
        />
        <Select
          label="Metric"
          placeholder="Select metric"
          allowDeselect={false}
          data={metricsOptions}
          {...form.getInputProps("metricId")}
          key={form.key("metricId")}
        />
        <Select
          w={120}
          label="Aggregate By"
          placeholder="Select aggregation"
          allowDeselect={false}
          data={AGG_BY_OPTIONS}
          {...form.getInputProps("aggBy")}
          key={form.key("aggBy")}
        />
        <Select
          w={300}
          label="Group By"
          placeholder="Select group by"
          allowDeselect={false}
          data={groupByOptions}
          searchable
          {...form.getInputProps("groupBy")}
          key={form.key("groupBy")}
        />
        <Tooltip label={datePickerTooltip}>
          <Group align="flex-end" gap={0}>
            <DatePickerInput
              label="Dates"
              allowSingleDateInRange={false}
              placeholder="Select time period"
              type="range"
              w={320}
              value={dateRange}
              onChange={handleSelectDateRange}
              styles={{
                input: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
              }}
            />
            <Select
              data={DATE_PRESET_OPTIONS}
              placeholder="Select date preset"
              {...form.getInputProps("datePreset")}
              key={form.key("datePreset")}
              onChange={handleSelectDatePreset}
              styles={{
                input: {
                  borderLeft: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                },
              }}
            />
          </Group>
        </Tooltip>
      </Group>
      <QueryBuilder
        key={form.key("whereClause")}
        {...form.getInputProps("whereClause")}
      />
    </Stack>
  )
}
