import { BarChart } from "@mantine/charts"

import { Filters } from "@costory/types/filters"

import WaterfallChartTooltip from "@costory/front/components/charts/waterfallChartTooltip"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useWaterfallQuery } from "@costory/front/queries/explore"
import {
  COLOR_PALETTE,
  PayloadItem,
  truncateLabel,
} from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
  drillDownInto?: (value: string) => void
}

const getBarColor = (cost: number, index: number, size: number): string => {
  if (index === 0 || index === size - 1) return "primary.5"
  return cost > 0 ? "red.5" : "green.5"
}

export const WaterfallChart = ({
  filters,
  height,
  withLegend,
  drillDownInto,
}: Props) => {
  const waterfallQuery = useWaterfallQuery(filters)

  return (
    <QueryWrapper query={waterfallQuery}>
      {({ data }) => (
        <BarChart
          h={height ?? "100%"}
          pr="30px"
          data={data.waterfallChartData.map((elem, index) => ({
            cost: elem.cost,
            name: truncateLabel(elem.name, 12),
            fullName: elem.name,
            color: getBarColor(
              elem.cost,
              index,
              data.waterfallChartData.length,
            ),
          }))}
          xAxisProps={{
            interval: 0,
            angle: 30,
            textAnchor: "start",
          }}
          yAxisProps={{
            allowDataOverflow: true,
          }}
          dataKey="name"
          type="waterfall"
          tooltipProps={{
            content: ({ payload }) => (
              <WaterfallChartTooltip
                label={payload?.[0]?.payload?.fullName ?? ""}
                payload={payload as PayloadItem[]}
                tooltipData={data.dataGroupedByValueWithOthers}
              />
            ),
          }}
          valueFormatter={(value) => formatNumber(value)}
          series={[{ name: "cost", color: COLOR_PALETTE[0] }]}
          withLegend={withLegend}
          barChartProps={{
            onClick: (data) => {
              drillDownInto &&
                data.activePayload &&
                data.activePayload.length > 0 &&
                drillDownInto(data.activePayload[0].payload.fullName)
            },
          }}
        />
      )}
    </QueryWrapper>
  )
}
